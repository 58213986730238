@use 'app/styles/variables' as ffcVars;
@use 'app/styles/mixins' as ffcMixins;
@use '@plus/ds-tokens/files/generated/tokens/mixins' as dsMixins;

@import '@plus/ds-tokens/files/generated/tokens/variables.css';
@import '@plus/ds-tokens/files/generated/tokens/mixins';

@import '@angular/cdk/overlay-prebuilt.css';
@import 'swiper/swiper.min.css';
@import 'app/styles/toastr';

@mixin rtl-united-font(
  $normal: true,
  $bold: true,
  $black: true,
  $swap: false
) {
  $path: './assets/fonts';

  @if $normal {
    @font-face {
      font-weight: 400;
      font-style: normal;
      font-family: 'RTL United Text';
      src: url('#{$path}/RTLUnitedText-Regular.otf');
      src: url('#{$path}/RTLUnitedText-Regular.otf') format('opentype'),
        url('#{$path}/RTLUnitedText-Regular.woff') format('woff'),
        url('#{$path}/RTLUnitedText-Regular.woff2') format('woff2');
      @if $swap {
        font-display: swap;
      }
    }

    @font-face {
      font-weight: 400;
      font-style: normal;
      font-family: 'RTL United Calculation';
      src: url('#{$path}/RTLUnitedCalculation-Regular.otf');
      src: url('#{$path}/RTLUnitedCalculation-Regular.otf') format('opentype'),
      url('#{$path}/RTLUnitedCalculation-Regular.woff') format('woff'),
      url('#{$path}/RTLUnitedCalculation-Regular.woff2') format('woff2');
      @if $swap {
        font-display: swap;
      }
    }
  }

  @if $bold {
    @font-face {
      font-weight: 700;
      font-style: normal;
      font-family: 'RTL United Text';
      src: url('#{$path}/RTLUnitedText-Bold.otf');
      src: url('#{$path}/RTLUnitedText-Bold.otf') format('opentype'),
        url('#{$path}/RTLUnitedText-Bold.woff') format('woff'),
        url('#{$path}/RTLUnitedText-Bold.woff2') format('woff2');
      @if $swap {
        font-display: swap;
      }
    }

    @font-face {
      font-weight: 700;
      font-style: normal;
      font-family: 'RTL United Calculation';
      src: url('#{$path}/RTLUnitedCalculation-Bold.otf');
      src: url('#{$path}/RTLUnitedCalculation-Bold.otf') format('opentype'),
      url('#{$path}/RTLUnitedCalculation-Bold.woff') format('woff'),
      url('#{$path}/RTLUnitedCalculation-Bold.woff2') format('woff2');
      @if $swap {
        font-display: swap;
      }
    }
  }
}

@include rtl-united-font(true, true, true);

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  background-color: var(--colors-background);
  color: var(--colors-ui-highlight);
  font: var(--typography-interface-body);
}

a {
  color: inherit;
}

ul {
  &.reset {
    padding-left: ffcVars.$spacing-md;
  }

  &.no-bullets {
    list-style: none;
    padding-left: 0;
  }

  li::marker {
    color: var(--colors-on-background-low-emphasis);
  }
}

.container {
  @include ffcMixins.container;
}

.container-xs {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: ffcVars.$spacing-sm;
  padding-right: ffcVars.$spacing-sm;
  max-width: ffcVars.$container-xs-width;

  &.billwerk-iframe-container {
    // there is a 60px padding inside the iframe
    max-width: ffcVars.$container-xs-width + 60px;
    padding-left: 0;
    padding-right: 0;
  }
}

.container-sm {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: ffcVars.$spacing-sm;
  padding-right: ffcVars.$spacing-sm;
  max-width: ffcVars.$container-sm-width;

  &.billwerk-iframe-container {
    // there is a 60px padding inside the iframe
    max-width: ffcVars.$container-sm-width + 60px;
    padding-left: 0;
    padding-right: 0;
  }
}

.container-md {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: ffcVars.$spacing-sm;
  padding-right: ffcVars.$spacing-sm;
  max-width: ffcVars.$container-md-width;

  &.billwerk-iframe-container {
    // there is a 60px padding inside the iframe
    max-width: ffcVars.$container-md-width + 60px;
    padding-left: 0;
    padding-right: 0;
  }
}

.mt-small {
  margin-top: ffcVars.$spacing-sm;
}

.mb-small {
  margin-bottom: ffcVars.$spacing-sm;
}

.mt-medium {
  margin-top: ffcVars.$spacing-md;
}

.mb-medium {
  margin-bottom: ffcVars.$spacing-md;
}

.mt-large {
  margin-top: ffcVars.$spacing-lg;
}

.mb-large {
  margin-bottom: ffcVars.$spacing-lg;
}

.mt-xlarge {
  margin-top: ffcVars.$spacing-lg * 1.5;
}

.mb-xlarge {
  margin-bottom: ffcVars.$spacing-lg * 1.5;
}

.form-grid {
  display: flex;
  flex-direction: column;
  gap: ffcVars.$spacing-sm;
}

.text-center {
  text-align: center;
}

/* stylelint-disable-next-line a11y/font-size-is-readable */
.text-small {
  font-size: 12px;
}

/**
* Typography
*/
.heading-regular {
  font: var(--typography-interface-title1);
}
.heading-bold {
  font: var(--typography-interface-title1-bold);
}
.heading-2-bold {
 font: var(--typography-interface-title2-bold);
}
.heading-3-bold {
  font: var(--typography-interface-title3-bold);
}
.subheading-regular {
  font: var(--typography-interface-title2);
}
.subheading-bold {
  font: var(--typography-interface-title2-bold);
}
.body-title-regular {
  font: var(--typography-interface-body-title);
}
.body-title-bold {
  font: var(--typography-interface-body-title-bold);
}
.text-regular {
  font: var(--typography-interface-body);
}
.text-bold {
  font: var(--typography-interface-body-bold);
}
.text-footnote {
  font: var(--typography-interface-footnote);
}
.caption-regular {
  font: var(--typography-interface-caption);
}
.caption-bold {
  font: var(--typography-interface-caption-bold);
}
.caption-title-regular {
  font: var(--typography-interface-caption-title);
}
.caption-title-bold {
  font: var(--typography-interface-caption-title-bold);
}
.callout-title-regular {
  font: var(--typography-interface-callout-title);
}

.callout-title-bold{
  font: var(--typography-interface-callout-title-bold);
}

/**
* Font colors
*/
.color {
  color: var(--colors-on-background-high-emphasis);

  &--default {
    color: var(--colors-on-background-high-emphasis);
  }
  &--medium-emphasis {
    color: var(--colors-on-background-medium-emphasis);
  }
  &--low-emphasis {
    color: var(--colors-on-background-low-emphasis);
  }
}

[hidden] {
  display: none !important;
}

.box {
  padding: 15px;
  @include ffcMixins.rounded-corners-md;

  &--highlight {
    background: var(--colors-background-highlight);
  }

  &--highlight-dark {
    background: var(--colors-ui-low-contrast);
  }

  &--p-fluid {
    padding: var(--sizes-16);

    @include dsMixins.breakpoints_sm {
      padding: var(--sizes-24);
    }

    @include dsMixins.breakpoints_lg {
      padding: var(--sizes-32);
    }
  }
}

.dl-box {
  background: var(--colors-ui-low-contrast);
  @include ffcMixins.rounded-corners-md();

  & > div {
    padding: var(--sizes-24) var(--sizes-16) 0;
  }

  & > dl {
    margin: 0;
  }

  & > dl > dt {
    font: var(--typography-interface-body-title-bold);
    padding: var(--sizes-16);

    &:nth-of-type(even) {
      background: var(--colors-background-highlight);
    }
  }

  & > dl > dd {
    font: var(--typography-interface-body-title);
    color: var(--colors-on-ui-medium-emphasis);
    padding: 0 var(--sizes-16) var(--sizes-16);
    margin-inline-start: 0;

    &:nth-of-type(even) {
      background: var(--colors-background-highlight);
    }
  }
}

.modal-backdrop {
  background-color: rgb(0 0 0 / 80%);
}

span.highlight {
  font-weight: 700;
  color: var(--colors-ui-highlight);
}

hr {
  border: 0;
  height: 1px;
  background-color: var(--colors-ui-high-contrast);

  &.dark {
    background-color: var(--colors-ui-medium-contrast);
  }
}
